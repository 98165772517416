import styled from 'styled-components';
import media from 'styles/media';
import { H1, H2 } from 'styles/typography';
import { magenta, offwhite } from 'styles/variables';

export const Wrapper = styled.div`
  position: relative;
  margin: 5rem 0 0;
  text-align: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  ${media.xs`
    margin: 4rem 0 0;
  `};

  div {
    &:after {
      background-color: ${offwhite};
      content: '1919 • 2019';
      font-family: 'Crimson Text', 'Noto Kufi Arabic', 'Noto Sans CJK SC',
        Times New Roman, serif;
      font-size: 1.375rem;
      font-style: italic;
      font-weight: 400;
      position: absolute;
      display: block;
      width: 120px;
      bottom: -0.6875rem;
      color: ${magenta};
      margin: 0 auto;
      left: 0;
      right: 0;

      ${media.xs`
      display: none;
    `};
    }
  }
`;

export const HPTitle = styled(H1)`
  color: ${magenta};
  display: inline-block;
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  border-top: 1px solid ${magenta};
  border-bottom: 1px solid ${magenta};
  letter-spacing: -2.55px;
  align-self: center;

  ${media.md`
    font-size: 4.2rem;
    line-height: 5rem;
    padding: 0.5rem;
  `};

  ${media.sm`
    font-size: 3.75rem;
    line-height: 4rem;
    margin: 0;
  `};

  ${media.xs`
    font-size: 3rem;
    line-height: 3.5rem;
    border: none;
    padding: 0;

    .ilo {
      display: block;
      line-height: 6rem;
      font-size: 5.3125rem;
    }
  `};
`;

export const Year = styled.div`
  display: none;
  ${media.xs`
    display: flex;
    position: relative;
    align-items: center;
    background-color: ${offwhite};
    justify-content: center;
    font-family: 'Crimson Text', 'Noto Kufi Arabic', 'Noto Sans CJK SC',
      Times New Roman, serif;
    font-size: 1.375rem;
    font-style: italic;
    font-weight: 400;
    margin: 1.5rem 0 0;
    position: relative;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 250px;
    width: 100%;

    &:before {
      border-top: 1px solid ${magenta};
      display: inline-block;
      content: " ";
      text-align: center;
      width: 100%;
      position: absolute;
      z-index: 0;
      left: 0;
      right: 0;
      bottom: 50%;
    }

    &:after {
      color: #e40046;
      content: '1919 • 2019';
      display: inline-block;
      background-color: ${offwhite};
      z-index: 1;
      padding: 0 15px;
    }
  `};
`;

export const Strap = styled(H2)``;
