import styled from 'styled-components';
import { H2 } from 'styles/typography';
import media from 'styles/media';

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  margin: 4rem 0 0;

  ${media.xs`
    margin: 2.5rem 0;
  `}
`;

export const Subtitle = styled(H2)`
  font-size: 1.5625rem;
  font-weight: 400;
  margin: 0;
`;
