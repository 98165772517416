import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Layout from 'containers/Layout';
import Header from 'components/Header';
import Language from 'components/Language';
import Share from 'components/Share';
import Hamburger from 'components/Hamburger';

import {
  MobMenuWrapper,
  MobMenu,
  CTA,
  ShareWrapper,
  MobOption,
  MobLanguageMenu
} from './styled';

const MobileMenu = ({ revealed, toggle }) => (
  <Layout>
    <MobMenuWrapper>
      <Hamburger handleClick={toggle} selected={revealed} role="button" />
      <MobMenu revealed={revealed}>
        <Header color="white" />
        <MobLanguageMenu>
          <Language
            render={({ value, languages, setLanguage }) =>
              languages.map(language => (
                <MobOption
                  key={language.value}
                  selected={language.value === value}
                  onClick={() => setLanguage(language.value)}
                >
                  {language.text}
                </MobOption>
              ))
            }
          />
        </MobLanguageMenu>
        <ShareWrapper>
          <CTA>
            <FormattedMessage id="interface_share" />
          </CTA>
          <Share size="large" color="white" />
        </ShareWrapper>
      </MobMenu>
    </MobMenuWrapper>
  </Layout>
);

const { bool, func } = PropTypes;
MobileMenu.propTypes = {
  revealed: bool.isRequired,
  toggle: func.isRequired
};

export default MobileMenu;
