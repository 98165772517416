import styled, { css } from 'styled-components';
import media from 'styles/media';
import {
  cobalt,
  sans,
  white,
  jailcell,
  grey,
  black,
  padding
} from 'styles/variables';
import arrow from 'assets/images/arrow.png';

const menuFont = css`
  font-family: ${sans};
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
`;

export const BodyWrapper = styled.div`
  height: 100%;
  width: 100%;

  ${media.xs`
    height: ${props => (props.mobileShown ? '100vh' : '100%')};
    overflow: ${props => (props.mobileShown ? 'hidden' : 'visible')};
  `};
`;

export const BodyContent = styled.div`
  display: ${props => (props.mobileshown ? 'none' : 'flex')};
`;

export const DeskMenuWrapper = styled.div`
  display: block;

  ${media.xs`
    display: none;
  `};
`;

export const DeskMenu = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  top: ${padding.top.lg}rem;
  right: ${padding.right.lg}rem;
  z-index: 1;
  width: 16rem;

  ${media.md`
    top: ${padding.top.md}rem;
    right: ${padding.right.md}rem;
  `};

  ${media.sm`
    top: ${padding.top.sm}rem;
    right: ${padding.right.sm}rem;
  `};
`;

export const LanguageMenu = styled.menu`
  display: none;
  padding: 0;
  background-color: #fff;
`;

export const LanguageSelect = styled.div`
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 0 0 2.5rem;
  position: relative;
`;

export const LanguageMenuWraper = styled.div`
  display: inline-block;
  flex-basis: content;
  position: relative;
  font-family: ${sans};
  padding: 1rem 0;
  width: 6rem;

  &:hover ${LanguageMenu}, &:active ${LanguageMenu} {
    position: absolute;
    display: block;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0px 0px 5px ${jailcell};
  }
`;

export const Option = styled.div`
  text-transform: uppercase;
  cursor: pointer;
  opacity: 0.6;
  padding: 0.4rem 1rem;

  &:hover {
    background-color: ${grey};
  }

  &:first-of-type {
    padding-top: 0.75rem;
  }

  &:last-of-type {
    padding-bottom: 0.75rem;
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  color: ${black};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  text-transform: capitalize;
  margin: 0;
  padding: 0;

  &:after {
    content: '';
    background-image: url(${arrow});
    background-repeat: no-repeat;
    display: inline-block;
    width: 17px;
    height: 10px;
    margin: 0 0 0 10px;
    position: absolute;
    top: 41%;
  }
`;

/* Mobile */

export const MobMenuWrapper = styled.div`
  display: none;

  ${media.xs`
    display: block;
  `};
`;

export const MobMenu = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  visibility: ${props => (props.revealed ? 'visible' : 'hidden')};
  opacity: ${props => (props.revealed ? '1' : '0')};
  background-color: ${cobalt};
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  height: 100vh;
  min-height: 500px;
  width: 100vw;
  z-index: 5;
  overflow: hidden;
  transition: visibility 250ms, opacity 250ms ease;
  padding: ${padding.inner.xs}rem ${padding.right.xs}rem 1rem
    ${padding.left.xs}rem;

  header {
    align-self: flex-start;
  }
`;

export const MobLanguageMenu = styled.menu`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding: 8vh 0 1rem;
  width: 80%;
  border-bottom: 1px solid white;
  flex: 0 0 68%;
`;

export const MobOption = styled.div`
  ${menuFont};
  cursor: pointer;
  color: ${white};
  opacity: ${props => (props.selected ? 1 : 0.5)};
  flex: 1 0 auto;
`;

export const Border = styled.div`
  border: 1px solid ${white};
`;

export const CTA = styled.div`
  ${menuFont};
  color: ${white};
  margin: 0 0 1rem;
`;

export const ShareWrapper = styled.div`
  margin: 0;
  width: 80%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  flex: 0 0 22%;
  max-height: 150px;
  padding: 1rem 0 0;
`;
