import React from 'react';
import { withIntl } from 'i18n';
import Layout from 'containers/Layout';
import Background from 'containers/Background';
import Head from 'components/Head';
import Header from 'components/Header';
import Footer from 'components/Footer';
import Menu from './Menu';
import { Wrapper, Main } from './styled';
import Title from './Title';
import Strap from './Strap';
import CallsToAction from './CallsToAction';

const Homepage = () => (
  <Menu>
    <Background theme="striped">
      <Head title="home_pageTitle" description="home_description" shareImg="home/homepage-share" />
      <Layout>
        <Wrapper>
          <Header />
          <Main>
            <Title />
            <Strap />
            <CallsToAction />
          </Main>
          <Footer />
        </Wrapper>
      </Layout>
    </Background>
  </Menu>
);

export default withIntl(Homepage);
