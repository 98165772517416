import React from 'react';
import { Link } from 'i18n';
import CloudinaryImage from 'components/Image';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Wrapper, CTA, Background, Filter, Button, Title, Subtitle } from './styled';

const CallsToAction = ({intl: { locale }}) => (
  <Wrapper locale={locale}>
    <div>
      <Row center="xs">
        <Col xs={11} sm={7} md={4} lg={5}>
          <CTA>
            <Link to="/story">
              <Background
                image={CloudinaryImage.getImageUrlById(
                  'home/ourstory-thumbnail'
                )}
              >
                <Filter>
                  <Title>
                    <FormattedMessage id='home_ourStory' />
                  </Title>
                  <Subtitle>
                    <FormattedMessage id='home_ourStorySubtitle' />
                  </Subtitle>
                  <Button featured>
                    <FormattedMessage id="home_ourStoryCta" />
                  </Button>
                </Filter>
              </Background>
            </Link>
          </CTA>
        </Col>
        <Col xs={11} sm={7} mdOffset={1} md={4} lgOffset={1} lg={5}>
          <CTA>
            <Link external to={`${process.env.GATSBY_PATH_PREFIX}/news/${locale}`}>
              <Background
                image={CloudinaryImage.getImageUrlById('home/news-thumbnail')}
              >
                <Filter>
                  <Title>
                    <FormattedMessage id='home_news' />
                  </Title>
                  <Subtitle>
                    <FormattedMessage id='home_newsSubtitle' />
                  </Subtitle>
                  <Button>
                    <FormattedMessage id="home_newsCta" />
                  </Button>
                </Filter>
              </Background>
            </Link>
          </CTA>
        </Col>
      </Row>
    </div>
  </Wrapper>
);

export default injectIntl(CallsToAction);
