import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Wrapper, HPTitle, Year } from './styled';

const Title = () => (
  <Row center="xs">
    <Col xs={12}>
      <Wrapper>
        <div>
          <HPTitle>
            <FormattedHTMLMessage id="home_title" />
          </HPTitle>
        </div>
        <Year />
      </Wrapper>
    </Col>
  </Row>
);

export default Title;
