import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Mobile from './Mobile';
import Desktop from './Desktop';
import { BodyWrapper, BodyContent } from './styled';

class Menu extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  };

  state = {
    mobileShown: false
  };

  toggle = () => {
    this.setState({
      mobileShown: !this.state.mobileShown
    });
  };

  render() {
    const { mobileShown } = this.state;

    return (
      <BodyWrapper mobileShown={mobileShown}>
        <Desktop />
        <Mobile revealed={mobileShown} toggle={this.toggle} />
        <BodyContent mobileShown={mobileShown}>
          {this.props.children}
        </BodyContent>
      </BodyWrapper>
    );
  }
}

export default Menu;
