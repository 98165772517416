import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Wrapper, Subtitle } from './styled';

const Strap = () => (
  <Row center="xs">
    <Col xs={12}>
      <Wrapper>
        <Subtitle>
          <FormattedMessage id="home_subtitle" />
        </Subtitle>
      </Wrapper>
    </Col>
  </Row>
);

export default Strap;
