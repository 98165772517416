import styled from 'styled-components';
import media from 'styles/media';
import { padding } from 'styles/variables';
import breakpoints from 'styles/breakpoints';

const { lg } = breakpoints;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 820px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  padding: ${padding.top.lg}rem ${padding.right.lg}rem 0 ${padding.left.lg}rem;

  ${media.md`
    min-height: 700px;
    padding: ${padding.top.md}rem ${padding.right.md}rem 0 ${padding.left.md}rem;
  `};

  ${media.sm`
    min-height: 940px;
    overflow: visible;
    padding: ${padding.top.sm}rem ${padding.right.sm}rem 0 ${padding.left.sm}rem;
  `};

  ${media.xs`
    min-height: none;
    height: 100%;
    overflow: visible;
    padding: ${padding.inner.xs}rem ${padding.right.xs}rem 0 ${padding.left.xs}rem;
  `};
`;

export const Main = styled.main`
  flex: 1 0 auto;
  width: 100%;
  max-width: ${lg}em;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
`;
