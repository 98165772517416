import styled, { keyframes, css } from 'styled-components';
import media from 'styles/media';
import { sans } from 'styles/variables';
import { H2, H3 } from 'styles/typography';

const shimmer = keyframes`
  0% {
     transform: translateX(-200px) skewX(-15deg);
     opacity: 0.5;
  }
  60% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 0.6;
  }
  100% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 0.6;
  }
`;

const afterglow = keyframes`
  0% {
     transform: translateX(-200px) skewX(-15deg);
     opacity: 0;
  }
  60% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 1;
  }
  100% {
    transform: translateX(400px) skewX(-15deg);
    opacity: 0.6;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  cursor: pointer;
  margin: 0;
  padding: 15px 5px;
  width: 230px;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-family: ${sans};
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  transition: all 0.4s ease;
  outline: 0;
  overflow: hidden;
  position: relative;

  /* Stops button text from twitching in IE */
  span {
    position: relative;
  }

  ${media.md`
    width: 190px;
  `};

  ${media.xs`
    width: 60%;
  `};

  &:before {
    content: '';
    display: ${props => (props.featured ? 'block' : 'none')};
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 100px;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    filter: blur(30px);
    animation: 3.5s infinite ${shimmer};
    transform: translateX(-200px) skewX(-15deg);
  }

  &:after {
    content: '';
    display: ${props => (props.featured ? 'block' : 'none')};
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 80px;
    height: 100%;
    left: 30px;
    top: 0;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-200px) skewX(-15deg);
    animation: 3.5s infinite ${afterglow};
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
  }

  ${props => css`
    button span {
      letter-spacing: ${props.locale === 'ar' ? '0 !important' : null};
    }
  `};
`;

export const CTA = styled.div`
  width: 100%;
  height: 300px;
  display: inline-block;
  overflow: hidden;

  ${media.md`
    height: 220px;
  `};

  &:first-child {
    ${media.sm`
      margin: 0 0 2rem 0;
    `};
  }

  &:hover ${Button} {
    background-color: #fff;
    color: #000;
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transition: transform 0.4s ease, opacity 0.4s ease;
  }

  &:hover:before {
    transform: scale(1.04);
  }
`;

export const Filter = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
`;

export const Title = styled(H2)`
  margin: 0;
  font-size: 1.75rem;
  font-weight: 400;
  color: #fff;
  text-align: center;

  ${media.md`
    font-size: 1.4375rem;
  `};
`;

export const Subtitle = styled(H3)`
  font-weight: 400;
  margin: 20px 0;

  ${media.md`
    font-size: 1rem;
  `};
`;
