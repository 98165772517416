import React from 'react';
import Language from 'components/Language';
import Share from 'components/Share';
import {
  DeskMenuWrapper,
  DeskMenu,
  LanguageSelect,
  LanguageMenuWraper,
  LanguageMenu,
  Button,
  Option
} from './styled';

const DekstopMenu = () => (
  <DeskMenuWrapper>
    <DeskMenu>
      <Share />
      <LanguageSelect>
        <Language
          render={({ value, languages, setLanguage, language }) => (
            <LanguageMenuWraper>
              <Button>{language}</Button>
              <LanguageMenu>
                {languages
                  .filter(lang => lang.value !== value)
                  .map(lang => (
                    <Option onClick={() => setLanguage(lang.value)} key={lang.value}>
                      {lang.text}
                    </Option>
                  ))}
              </LanguageMenu>
            </LanguageMenuWraper>
          )}
        />
      </LanguageSelect>
    </DeskMenu>
  </DeskMenuWrapper>
);

export default DekstopMenu;
